.processing-loader-container {
  padding-top: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  color: white;
  font-weight: 700;
  font-size: x-large;
  min-width: -webkit-fill-available;
}
